.be-chip {
  padding: 10px 15px;
  border-radius: 36px;
  background-color: lighten(#eeba4e, 30%);

  &.item {
    --background: transparent;
    --border-color: transparent;
    padding: 0;

    ion-checkbox {
      zoom: 1;
    }
  }

  .sc-ion-label-md-h,
  .sc-ion-label-md-s {
    font-weight: $font-bold;
    font-size: $text-x-small;
  }
}

.focus-chip {
  background-color: #1d5357;
  color: white;
  padding: 5px 10px;
  & ion-label {
    font-size: 16px;
    font-weight: 700;
  }
}

.results-chip {
  background: #ed7d31;
  color: white;
  padding: 5px 10px;
  & ion-label {
    font-size: 16px;
    font-weight: 700;
  }
}
