.be-item{
  &.item{
    margin-bottom: 10px;
    position: relative;
    transition: all 1s ease;

    &:last-child{
      margin-bottom: 20px;
    }
  }
  &.admin-item {
    margin-top: 25px;
  }

  .sc-ion-label-md-h,
  .sc-ion-label-md-s{
    -webkit-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
    transition: all 1s ease;
  }
}