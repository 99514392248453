.game-status{
  font-size: $text-normal;
  text-align: center;
  font-weight: $font-normal;

  .timer{
    font-size: $text-x-small;
    font-family: $tertiary-font-stack;
    margin-bottom: 5px;
  }

  .score{
    &:before, &:after{
      content: "";
      border-top: 1px solid #000;
      width: 30px;
      display: inline-block;
      position: relative;
      bottom: 8px;
      opacity: 0.5;
    }
  }
}
