/**- Wrappers -**/
.header-wrapper{
  padding: 0 20px;
}

.container{
  padding: 20px;
  margin: 0 auto;
  &.flex {
    display: flex;
    flex-direction: column;
    height: 70%;
  }
}
.flex-1 {
  flex: 1;
}

.padded-wrapper{
  padding: 10px 20px;
}

/**- Alignment -**/
.flex-vertical-center{
  align-items: center;
}
.flex-horizontal-center{
  justify-content: center;
}

.content{
  &-center{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &-vertical{
      display: flex;
      align-items: center;
    }
  }

  &-end{
    justify-content: flex-end;
  }

  &-start{
    justify-content: flex-start;
  }
}

.flex{
  display: flex;
}

.flex-full-height{
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

.flex-wrapper{
  display: flex;
  flex-direction: column;
}

.flex-grow{
  flex-grow: 1;
}

.flex-stretch-vertical{
  flex: 1;
}

.full-height{
  height: 100%;
}

.full-width{
  width: 100%;
}

.flex-space-between{
  justify-content: space-between;
}
.center-with-footer {
  height: 90%;
  overflow: auto;
}
.center-with-logo{
  width: 100%;
  text-align: center;
  margin-left: -65px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 100%;
  }
}
