$image-path:  '../../assets/images/';
$round:       100px;

/**- color -**/
$gray:        #9b9b9b;
$dark-gray:   #666666;
$light-gray:  #dddddd;
$pale-gray:   #efefef;
$faded-gray:  rgba(216, 216, 216, 0.2);

$opaque-white:rgba(255, 255, 255, 0.7);

$green:       #24a38c;
$pale-green:  rgba(184, 233, 134, 0.5);
$aqua-green:  #689ba4;
$success:     #76d672;
$light-green: #d4edda;

$light-yellow:#fff3db;
$pale-yellow: #fffefb;
$yellow:      #EAAA00;

$light-red:   rgba(225, 169, 164, 0.5);
$pink:        #FB48C4;
$red:         #d30000;

$blue:        #3d87f2;
$shape-red:   #fa0707;

$black:       #000;
$orange:      #FF7A32;
$purple:      #800080;
$brown:       #9e663e;

$primary-color: var(--ion-color-primary);
$secondary-color: var(--ion-color-secondary);
$tertiary-color: var(--ion-color-tertiary);
$primary-pink: var(--ion-color-primary-pink);
$primary-grey: var(--ion-color-primary-grey);
$primary-yellow: var(--ion-color-primary-yellow);

/**- font -**/
$primary-font-stack: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Tahoma', 'sans-serif';
$secondary-font-stack: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Tahoma', 'sans-serif';
$tertiary-font-stack: 'Boogaloo', 'Helvetica Neue', 'Helvetica', 'Tahoma', 'sans-serif';

$font-light:      300;
$font-medium:     500;
$font-bold:       700;
$font-normal:     400;

$text-x-large:    4.5rem;
$text-large:      3rem;
$text-medium:     2.5rem;
$text-small:      2.0rem;
$text-x-small:    1.8rem;
$text-mobile:     1.2rem;
$base-text:       1.5rem;
$text-normal:     1rem;

$validation-text: 1.4rem;

$radius:          8px;
$zoom:            1.4;

$screen-normal:   768px;
$screen-large:    1200px;
$screen-medium:   1024px;
$screen-small:    320px;
$screen-small-max:480px;

$screen-min:      599px;
$screen-max:      899px;
$tablet-min:      $screen-max;
$tablet-max:      1280px;
