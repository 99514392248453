.be-item {
  .be-input {
    font-size: $text-medium;
    color: $black;
    transition: all 1s ease;

    &.admin {
      font-size: $text-small;
    }
  }
  .be-select {
    font-size: $text-small;
    color: $black;
    transition: all 1s ease;

    &.admin {
      font-size: $text-small;
    }
  }
}

.item-interactive.sc-ion-item-md-h {
  .ion-invalid.ion-touched {
    --border-color: var(--ion-color-danger-shade);
    --border-width: 0 0 2px 0;
    transition: all 1s ease;
  }
}
