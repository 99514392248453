.be-progress-bar {
  zoom: 4;
  border-radius: $radius;
}
.chart-progress-bar {
  height: 40px;
  border-radius: 0;
  background: #000;
  margin-top: 5px;
  .progress-bar {
    &.color-1 {
      background-color: rgb(108, 140, 134);
    }
    &.color-2 {
      background-color: rgb(114, 178, 189);
    }
    &.color-3 {
      background-color: rgb(188, 228, 221);
    }
    color: #000;
    font-size: $text-normal;
  }
}
.chart-wrapper {
  height: 50px;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}