.select-popover {
  ion-list-header {
    font-size: $text-x-small;
  }

  .popover-content {
    right: 35px;
    left: inherit !important;
    width: 350px;
  }

  .sc-ion-label-md-h,
  .sc-ion-label-md-s {
    font-size: $text-x-small;
  }
}
