%swiper-bullets{
  width: 15px;
  height: 15px;
}

swiper.be-swiper{
  overflow: hidden;
  
  > .swiper{
    &.s-wrapper{
      .swiper-wrapper{
        .swiper-slide{
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
      }

      .swiper-pagination{
        .swiper-pagination-handle{
          .swiper-pagination-bullet{

            &.swiper-pagination-bullet-first,
            &.swiper-pagination-bullet-last,
            &.swiper-pagination-bullet-middle{
              border: 2px solid $aqua-green;
            }
          }
        }
      }
    }
  }

  .swiper-pagination-bullet{
    @extend %swiper-bullets;
    background: #fff;
    opacity: 1;

    &-active{
      background: $aqua-green;
    }
  }

  .swiper-container-horizontal{
    &>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction{
      bottom: 0;
    }
  }
}

.swiper-slide{
  display: flex;
  flex-direction: column;
}

.be-dots{
  @extend %swiper-bullets;
  display: inline-block;
  border-radius: 100%;
  background: transparent;
  border: 1px solid #fff;
  margin-right: 15px;

  &:last-child{
    margin-right: 0;
  }

  &.active{
    background: #fff;
  }
}

@media only screen 
and (min-width: $screen-small)
and (max-width: $screen-small-max) {
}

