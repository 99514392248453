.be-icon{
  position: relative;
  top: 3px;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.icon {
  background-size: 100%;
  height: 60px;
  width: 60px;
  &.mood-happy {
    background-image: url('#{$image-path}mood/smile.png');
    &.white {
      background-image: url('#{$image-path}mood/smile-white.png');
    }   
  }
  &.mood-sad {
    background-image: url('#{$image-path}mood/sad.png');   
    &.white {
      background-image: url('#{$image-path}mood/sad-white.png');
    } 
  }
  &.mood-meh {
    background-image: url('#{$image-path}mood/meh.png');
    &.white {
      background-image: url('#{$image-path}mood/meh-white.png');
    }
  }
}

@media only screen 
and (min-width: $tablet-min)
and (max-width: $tablet-max) {
  .icon {
    height: 80px;
    width: 80px;
  }
}
