body{
  font-size: $base-text;
  font-family: $primary-font-stack;
}

a{
  color: #2222cc;
  font-size: $text-small;
  cursor: pointer;
}

h1{
  font-size: $text-medium;
}

@media only screen 
and (min-width: $screen-small)
and (max-width: $screen-small-max) {
  body {
    font-size: $base-text;
  }
  
  a {
    font-size: $text-small;
  }
}