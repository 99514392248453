.game-image {
  height: 170px;
  background-size: cover;
  background-position: center;
  transition: all 1s ease;
  max-height: 33%;
}
/**- Color -**/
.color-primary {
  color: var(--ion-color-primary);
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.color-white {
  color: #fff;
}

.brain-body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  background-color: #f0f2f5;
}

app-footer{
  margin-top: auto;
}

/**- Backgrounds -**/
.background {
  &-brainevolved{
    background-color: rgb(29, 83, 87);
  }
  &-primary {
    background-color: #f0f2f5;
    overflow: auto;
    &-green {
      padding: 15px;
      background-color: var(--ion-color-green);
    }
    &-pink {
      padding: 15px;
      background-color: var(--ion-color-primary-pink);
    }
    &-grey {
      padding: 15px;
      background-color: var(--ion-color-primary-grey);
    }
    &-yellow {
      padding: 15px;
      background-color: var(--ion-color-primary-yellow-shade);
    }
  }
  &-secondary {
    padding: 15px;
    background-color: var(--ion-color-secondary);
  }

  &-white {
    background-color: #fff;
  }

  &-yellow {
    background-color: $light-yellow;
  }

  &-faded-gray {
    background-color: $faded-gray;
  }

  &-light-gray {
    background-color: $light-gray;
  }
  &-pale-gray {
    background-color: $pale-gray;
  }

  &-padded {
    padding: 15px 20px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

.overlay {
  position: absolute;
  width: 100%;
  background: #fff;
  opacity: 0.8;
  height: 100%;
  left: -1px;
  top: -1px;
  z-index: 1;
}

/**- Titles -**/
.main-heading {
  font-size: $text-x-large;
  font-family: $tertiary-font-stack;
  text-align: center;
}

.title {
  font-size: $base-text;
  font-family: $tertiary-font-stack;
  text-transform: uppercase;
}

.main-title {
  font-size: $text-medium;
  font-family: $tertiary-font-stack;
  text-transform: uppercase;
}

.step-title {
  font-size: $text-large;
  font-weight: 700;
  font-family: $primary-font-stack;
  color: #004473;
}
.step-sub-title {
  font-size: $text-small;
  margin: 40px 0 0px;
}
.step-instructions {
  font-size: $text-x-small;
  font-weight: $font-light;
  color: black;
}
.context-title {
  font-size: $text-x-small;
  font-weight: $font-light;
  color: $gray;
  display: block;
}
.section-title {
  padding-left: 36px;
  padding-right: 36px;
  text-transform: uppercase;
  background: $faded-gray;
}
.text-small {
  font-size: $base-text;
  &.grey {
    color: $gray;
  }
}
/**- Branding -**/
.logo {
  background-image: url("#{$image-path}brain-charge-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  min-width: 200px;
  margin: 0 auto;

  &.large {
    width: 150px;
    height: 150px;
  }
  &.small {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
  }
}

.club-house {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2Fbe-brain-socialize.png?alt=media&token=457ceb5e-c47b-453b-b352-8b16066b689f");

  &.small {
    width: 40px;
    height: 40px;
  }
}
.happy-place {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/brain-evolved.appspot.com/o/Logo%2FhpLogo.png?alt=media&token=5daf14ec-632e-4678-8d83-75fb7066865c");

  &.small {
    width: 40px;
    height: 40px;
  }
}
.brain-game-logo {
  background-image: url("#{$image-path}logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 50px;
  margin: 0 auto;

  &.small {
    width: 40px;
    height: 40px;
  }
}

.brand-name {
  align-self: baseline;
  width: 100%;
  font-size: 1.2rem;

  &.top {
    position: static;
  }
}

.no-margin {
  margin: 0;
}

/**- Text -**/
.zoom {
  zoom: $zoom;
}

.bold {
  font-weight: bold !important;
}

.error {
  color: var(--ion-color-danger-shade);
  font-weight: $font-normal;
  font-size: $text-x-small;
  margin-bottom: 10px;
  padding: 0 12px;

  &.validation {
    font-size: $text-x-small;
    padding-left: 16px;
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.font-primary {
  font-family: $primary-font-stack;
}

.font-secondary {
  font-family: $secondary-font-stack;
}

.font-tertiary {
  font-family: $tertiary-font-stack;
}
.font-weight-light {
  font-weight: $font-light;
}
.font-medium-light {
  font-weight: $font-medium;
}
.font-bold-light {
  font-weight: $font-bold;
}

.text-x-large {
  font-size: $text-x-large;
}

.text-large {
  font-size: $text-large;
}

.text-medium {
  font-size: $text-medium;
}

.text-small {
  font-size: $text-small;
}

.text-x-small {
  font-size: $text-x-small;
}

.text-normal {
  font-size: $text-normal;
}
.text-base {
  font-size: $base-text;
}
.text-size-mobile {
  font-size: $text-mobile;
}

.text-pre-wrapped {
  white-space: pre-wrap;
}

.description {
  font-weight: $font-light;
  line-height: 150%;
}

.close {
  font-size: $text-large;
  color: #fff;
  font-weight: $font-bold;
}

/**- Border -**/
.bordered {
  border-bottom: 1px solid $light-gray;

  &-right {
    border-right: 1px solid $light-gray;
  }
  &-left {
    border-left: 1px solid $light-gray;
  }
  &-top {
    border-top: 1px solid $light-gray;
  }
  &-bottom {
    border-bottom: 1px solid $light-gray;
  }
}

hr {
  @extend .bordered;
  height: 1px;
  margin: 0;

  &.primary {
    border-color: var(--ion-color-primary);
  }
}

/**- image -**/
.avatar {
  height: 70px;
  width: 70px;
  background: $light-gray;
  border: 1px solid $gray;
  overflow: hidden;
  border-radius: 100px;
  margin-left: 20px;
}

/**- CTOs -**/
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  cursor: pointer;
  height: 50px;
  width: 50px;
  color: #fff;

  &.remove {
    background: $red;
  }

  &.edit {
    margin-right: 5px;
    background: $green;
  }
}

.checkmark {
  color: $green;
  font-size: $text-medium;
}

.button-label {
  font-size: $base-text;
  font-weight: $font-light;
}

.be-tab {
  &:hover,
  &.highlight {
    color: $green;
  }
}

.number {
  font-size: $base-text;
  padding-left: 10px;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  background: #fff;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h1 {
    margin-top: -80px;
  }
}

.color {
  font-weight: $font-bold;
  font-weight: $text-small;
  padding-left: 10px;
}
.color-green {
  color: $green;
}

.color-orange {
  color: $orange;
}

.color-blue {
  color: $blue;
}

.color-yellow {
  color: $yellow;
}

.color-purple {
  color: $purple;
}

.color-pink {
  color: $pink;
}

.color-red {
  color: $red;
}

.color-brown {
  color: $brown
}

.yahootie-hooray {
  // background-image: url("#{$image-path}yahootie-confetti.gif");
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
}

@media only screen and (min-width: $screen-small) and (max-width: $screen-small-max) {
  .main-title,
  .title {
    font-size: $base-text;
    margin: 0;
  }

  .brand-name {
    font-size: 0.8rem;
  }

  .logo,
  .brain-game-logo {
    &.small {
      height: 30px;
      width: 30px;
    }
  }
}

@media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
  .step-title {
    font-size: $text-large;
  }
  .step-instructions {
    font-size: $text-medium;
  }
}
