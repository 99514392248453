%badge-size{
  background-size: 32px 55px;
  height: 55px;
  width: 32px;
  background-repeat: no-repeat;
  position: absolute;    
  top: -5px;
  left: 20px;
  font-weight: $font-bold;
  font-size: $text-mobile;  
  text-align: center;
  padding-top: 10px;

  &.first{
    top: -11px;
  }
}

.badge{
  @extend %badge-size;

  &-gold{
    background-image: url('#{$image-path}gold-badge.png');
  }

  &-silver{
    background-image: url('#{$image-path}silver-badge.png');  
  }

  &-bronze{
    background-image: url('#{$image-path}bronze-badge.png');  
  }  
}