// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "scss/brain-evolved.scss";
@import '~@fortawesome/fontawesome-free/css/all.css';


// tweaking the default popover styling from ionic
.popover-content {
  width: 500px !important;
}

// media query so the styling of the popover never escapes the width of the device
@media (max-width: 500px) {
  .popover-content {
    width: 80% !important;
  }
}
