/**- Helpers -**/
@import 'helpers/_font';
@import 'helpers/_vars';
// @import 'helpers/_mixin';

/**- Base -**/
@import 'base/_typography';

/**- Layout -**/
@import 'layout/_layout';

/**- Components -**/
@import 'components/_button';
@import 'components/_label';
@import 'components/_title';
@import 'components/_input';
@import 'components/_score';
@import 'components/_layout';
@import 'components/_card';
@import 'components/_header';
@import 'components/_swiper';
@import 'components/_modal';
@import 'components/_toolbar';
@import 'components/_toggle';
@import 'components/_popover';
@import 'components/_select';
@import 'components/_radio';
@import 'components/_items';
@import 'components/_alert';
@import 'components/_toast';
@import 'components/_loader';
@import 'components/_icons';
@import 'components/_list';
@import 'components/_menu';
@import 'components/_datepicker';
@import 'components/_tabs';
@import 'components/_footer';
@import 'components/_image';
@import 'components/_progress';
@import 'components/_chip';
@import 'components/_badges';
@import 'components/_icons';

/**- Pages -**/
@import 'pages/_trivia';
@import 'pages/_leaderboard';
@import 'pages/_instructions';
@import 'pages/_splash';
@import 'pages/_home';

// im going insane
@import url('https://kit.fontawesome.com/7e23d2b721.js');

/**- DO NOT MOVE FROM THE BOTTOM -**/
@import 'helpers/_global';