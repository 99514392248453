/**- Tertiary -**/
%button-style{
  border: 1px solid $gray;
  box-shadow: inset 2px -5px 0 0 $gray;
  transition: all 1s ease;
  outline: none;
}

%button-shadow{
  box-shadow: inset 2px -3px 0 0 $green;
  transition: all 1s ease;
}
%button-shadow-pink{
  --box-shadow: inset 2px -3px 0 0  var(--ion-color-secondary-pink-tint);
  transition: all 1s ease;
}
%button-shadow-grey{
  --box-shadow: inset 2px -3px 0 0  var(--ion-color-primary-grey-tint);
  transition: all 1s ease;
}
%button-shadow-yellow{
  --box-shadow: inset 2px -3px 0 0  var(--ion-color-primary-yellow-tint);
  transition: all 1s ease;
}
%button-grey-shadow {
  --box-shadow: inset 2px -3px 0 0 #9B9B9B;
  transition: all 1s ease;
}
%button-green-shadow {
  --box-shadow: inset 2px -3px 0 0 var(--ion-color-secondary-tint);
  transition: all 1s ease;
}

.se-fab{
  zoom: 1.4;
  transition: all 1s ease;

  ion-icon{
    font-size: $text-small;
  }
}

.ion-default-button-style {
  height: auto;
  font-size: $text-small;
  font-weight: $font-bold;
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  transition: all 1s ease;
}
%default-button-style {
  height: auto;
  font-size: $text-small;
  font-weight: $font-bold;
  padding: 20px 40px;
  border-radius: 64px;
  transition: all 1s ease;
  text-transform: uppercase;
  outline: none;
}

.be-button-tertiary{
  --border-width: 1px solid $gray;

  &.button,
  &.be-button{
    @extend %button-grey-shadow;
  }
  &.be-button{
    background: $tertiary-color;
    color: #000;
  }

  .button-native{
    &.sc-ion-button-md{
      @extend %button-style;
    }
  }
}

.be-button-primary{
  &.button,
  &.be-button{
    @extend %button-green-shadow;
  }
  &.be-button{
    background: $primary-color;
    color: #fff;
  }
  .button-native{
    &.sc-ion-button-md{
      @extend %button-shadow;
    }
  }
}

.be-button-primary-pink{
  &.button,
  &.be-button{
    @extend %button-shadow-pink;
  }
  &.be-button{
    background: $primary-pink;
    color: #fff;
  }
  .button-native{
    &.sc-ion-button-md{
      @extend %button-shadow;
    }
  }
}
.be-button-primary-grey{
  &.button,
  &.be-button{
    @extend %button-shadow-grey;
  }
  &.be-button{
    background: $primary-grey;
    color: #fff;
  }
  .button-native{
    &.sc-ion-button-md{
      @extend %button-shadow;
    }
  }
}
.be-button-primary-yellow{
  &.button,
  &.be-button{
    @extend %button-shadow-yellow;
  }
  &.be-button{
    background: $primary-yellow;
    color: #fff;
  }
  .button-native{
    &.sc-ion-button-md{
      @extend %button-shadow;
    }
  }
}

.be-button-secondary{
  &.button,
  &.be-button{
    @extend %button-green-shadow;
  }
  &.be-button{
    background: var(--ion-color-secondary);
    color: #fff;
  }
  .button-native{
    &.sc-ion-button-md{
      @extend %button-shadow;
    }
  }
  &.pink {
    .button-native{
      &.sc-ion-button-md{
        @extend %button-shadow-pink;
      }
    }
  }
}

.be-button-primary,
.be-button-tertiary,
.be-button-secondary,
.be-button-primary-pink ,
.be-button-primary-grey,
.be-button-primary-yellow{
  &.button {
    height: auto;
    font-size: $text-small;
    --padding-start: 40px;
    --padding-end: 40px;
    --padding-top: 20px;
    --padding-bottom: 20px;
    transition: all 1s ease;
    outline: none;
  }

  &.be-button {
    @extend %default-button-style;
    &.button_small {
      font-size: $text-normal;
      padding: 10px 20px;
    }
  }
}

.button-full {
  margin-left: 0;
  margin-right: 0;
  display: block;
  border: 2px solid lightgray;
  width: 100%;
  contain: content;
}

.admin{
  &.be-button-primary,
  &.be-button-tertiary{
    &.button{
      font-size: $text-x-small;
    }
  }
}


/**- all buttons -**/
.button-native{
  &.sc-ion-button-md{
    @extend .ion-default-button-style;
  }
}

.no-margin{
  .button-native{
    &.sc-ion-button-md{
      margin: 0;
    }
  }
}

.sc-ion-buttons-md-s{
  .button{
    zoom: 1.4;
  }
}

.tab-button{
  &.button{
    &.button-clear{
      --height: auto;
    }
  }
}
.grey-dashboard-button {
  background: $faded-gray;
  border-radius: 10px;
  padding: 50px 30px;
  cursor: pointer;

  .dash-icon {
    margin: 0;
    font-size: 90px;
    color: $green;
  }
  .dash-sub-title {
    font-size: $text-mobile;
    color: $gray;
    font-weight: $font-light;
    margin-top: 20px;
  }
  &:hover,
  &:focus {
    background: $gray;
    .dash-icon,
    .dash-sub-title {
      color: #fff;
    }
  }
}

@media only screen and (max-width: $screen-min) {
  .be-button-primary,
  .be-button-tertiary,
  .be-button-primary-pink,
  .be-button-primary-grey,
  .be-button-primary-yellow{
    &.button,
    &.be-button{
      font-size: $base-text;
      --padding-start: 20px;
      --padding-end: 20px;
    }
  }
}

@media only screen and (max-width: $screen-small-max) {
  .be-button-primary,
  .be-button-tertiary,
  .be-button-primary-pink,
  .be-button-primary-grey,
  .be-button-primary-yellow{
    &.button,
    &.be-button{
      font-size: $base-text;
    }
  }

  .button-native {
    font-size: $base-text;

    &.sc-ion-button-md{
      font-size: $base-text;
    }
  }
}