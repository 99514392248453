// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #184245;
  --ion-color-primary-rgb: 24, 66, 69;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #153a3d;
  --ion-color-primary-tint: #2f5558;

  /** secondary **/
  --ion-color-secondary: #70b4be;
  --ion-color-secondary-rgb: 112, 180, 190;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #639ea7;
  --ion-color-secondary-tint: #7ebcc5;

  /** tertiary **/
  // --ion-color-tertiary: #cccccc;
  // --ion-color-tertiary-rgb: 244,245,255;
  // --ion-color-tertiary-contrast: #000000;
  // --ion-color-tertiary-contrast-rgb: 0,0,0;
  // --ion-color-tertiary-shade: #b4b4b4;
  // --ion-color-tertiary-tint: #d1d1d1;
  --ion-color-tertiary: #f4f5f8;
  --ion-color-tertiary-rgb: 244, 245, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d7d8da;
  --ion-color-tertiary-tint: #f5f6f9;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #cecece;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** orange **/
  --ion-color-orange: #eeba4e;
  --ion-color-orange-rgb: 238, 186, 78;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #d1a445;
  --ion-color-orange-tint: #f0c160;

  /** primary green **/
  --ion-color-green: #7fd7c8;
  --ion-color-green-rgb: 127, 215, 200;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #70bdb0;
  --ion-color-green-tint: #8cdbce;

  /** secondary green **/
  --ion-color-secondary-green: #24a38c;
  --ion-color-secondary-green-rgb: 12, 209, 232;
  --ion-color-secondary-green-contrast: #ffffff;
  --ion-color-secondary-green-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-green-shade: #0bb8cc;
  --ion-color-secondary-green-tint: #24a38c;

  /** primary grey **/
  --ion-color-primary-grey: #3e403a;
  --ion-color-primary-grey-rgb: 62, 64, 58;
  --ion-color-primary-grey-contrast: #ffffff;
  --ion-color-primary-grey-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey-shade: #373833;
  --ion-color-primary-grey-tint: #51534e;

  /** assessment primary **/
  --ion-color-primary-pink: #b86862;
  --ion-color-primary-pink-rgb: 127, 215, 200;
  --ion-color-primary-pink-contrast: #ffffff;
  --ion-color-primary-pink-contrast-rgb: 0, 0, 0;
  --ion-color-primary-pink-shade: #a25c56;
  --ion-color-primary-pink-tint: #bf7772;

  /** assessment secondary **/
  --ion-color-secondary-pink: #bf4040;
  --ion-color-secondary-pink-rgb: 12, 209, 232;
  --ion-color-secondary-pink-contrast: #000000;
  --ion-color-secondary-pink-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-pink-shade: #a83838;
  --ion-color-secondary-pink-tint: #c55353;

  /** happy place **/
  --ion-color-primary-yellow: #e0d07d;
  --ion-color-primary-yellow-rgb: 224, 208, 125;
  --ion-color-primary-yellow-contrast: #000000;
  --ion-color-primary-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-primary-yellow-shade: #c5b76e;
  --ion-color-primary-yellow-tint: #e3d58a;

  /** brain game rebranding **/
  --ion-color-primary-blue: #3c5457;
  --ion-color-primary-blue-light: #4a5f62;

  .ion-color-orange {
    --ion-color-base: var(--ion-color-orange);
    --ion-color-base-rgb: var(--ion-color-orange-rgb);
    --ion-color-contrast: var(--ion-color-orange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
    --ion-color-shade: var(--ion-color-orange-shade);
    --ion-color-tint: var(--ion-color-orange-tint);
  }

  /** brain game **/
  .ion-color-primary-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
  }

  .ion-color-secondary-green {
    --ion-color-base: var(--ion-color-secondary-green);
    --ion-color-base-rgb: var(--ion-color-secondary-green-rgb);
    --ion-color-contrast: var(--ion-color-secondary-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-green-shade);
    --ion-color-tint: var(--ion-color-secondary-green-tint);
  }

  /** assessment **/
  .ion-color-primary-pink {
    --ion-color-base: var(--ion-color-primary-pink);
    --ion-color-base-rgb: var(--ion-color-primary-pink-rgb);
    --ion-color-contrast: var(--ion-color-primary-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-pink-shade);
    --ion-color-tint: var(--ion-color-primary-pink-tint);
  }

  .ion-color-secondary-pink {
    --ion-color-base: var(--ion-color-secondary-pink);
    --ion-color-base-rgb: var(--ion-color-secondary-pink-rgb);
    --ion-color-contrast: var(--ion-color-secondary-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-pink-shade);
    --ion-color-tint: var(--ion-color-secondary-pink-tint);
  }

  /** club house **/
  .ion-color-primary-grey {
    --ion-color-base: var(--ion-color-primary-grey);
    --ion-color-base-rgb: var(--ion-color-primary-grey-rgb);
    --ion-color-contrast: var(--ion-color-primary-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-grey-shade);
    --ion-color-tint: var(--ion-color-primary-grey-tint);
  }

  /** happy place **/
  .ion-color-primary-yellow {
    --ion-color-base: var(--ion-color-primary-yellow);
    --ion-color-base-rgb: var(--ion-color-primary-yellow-rgb);
    --ion-color-contrast: var(--ion-color-primary-yellow-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-yellow-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-yellow-shade);
    --ion-color-tint: var(--ion-color-primary-yellow-tint);
  }
}
