.splash-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a9dbe0+0,70b4be+50 */
  background: #a9dbe0; /* Old browsers */
  background: -moz-radial-gradient(top,  #a9dbe0 0%, #70b4be 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(top,  #a9dbe0 0%,#70b4be 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(to bottom,  #a9dbe0 0%,#70b4be 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a9dbe0', endColorstr='#70b4be',GradientType=0 ); /* IE6-9 */
  height: 100%;
  display: flex;
  justify-content: center;
}