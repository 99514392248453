.leader-board-modal{
  .modal-wrapper, .modal-wrapper-md{
    width: 100%;
    height: 100%;
  }  
}

.cutom-modal{
  .modal-wrapper {
    width: 700px;
    height: 600px;
  }
}
.didyouknow-modal{
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
}

.gameScore-modal{
  .modal-wrapper {
    width: 500px;
    height: 369px;
    border: solid 2px #1d5357;
  }
}

.xpEarned-modal{
  .modal-wrapper {
    width: 800px;
    height: 500px;
    border: solid 2px #1d5357;
  }
}

.endscreen-modal{
  .modal-wrapper {
    width: 800px;
    height: 740px;
  }
}

.leaderboard-modal{
  .modal-wrapper {
    width: 800px;
    height: 670px;
  }
}


.highlights-modal{
  .modal-wrapper {
    width: 800px;
    height: 340px;
  }
}
.voice-select-modal{
  .modal-wrapper {
    width: 600px;
    height: 195px;
  }
}

.returning-modal{
  .modal-wrapper{
    width: 500px;
    height: 250px;
  }
}

.hp-mood {
  .modal-wrapper {
    width: 700px;
    height: 380px;
  }
}

.trivia-modal {
  .modal-wrapper{
    width: 830px;
    height: 500px;
  }
}

.modal-fullscreen {
  .modal-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
  }
}

.training-path {
  .modal-wrapper {
    width: 750px;
    height: 425px;
  }
}

.hp-training-path {
  .modal-wrapper {
    width: 750px;
    height: 460px;
  }
}

.factor-modal {
  .modal-wrapper {
    width: 700px;
    height: 600px;
  }
}

.flyout-modal {
  .modal-wrapper {
    width: 700px;
    height: 100%;
    position: absolute;
    right: 0;
  }
}

.outside-training-modal {
  .modal-wrapper {
    width: 800px;
    height: 600px;
  }
}

.meeting-modal {
  .modal-wrapper {
    width: 750px;
    height: 750px;
  }
}

.edit-meeting-modal {
  .modal-wrapper {
    width: 1050px;
    height: 750px;
  }
}

.calendar-modal {
  .modal-wrapper {
    width: 850px;
    height: 750px;
  }
}

.delete-meeting-modal {
  .modal-wrapper {
    width: 750px;
    height: 400px;
  }
}

.fork-meeting-modal {
  .modal-wrapper {
    width: 1050px;
    height: 520px;
  }
}

.review-pres-modal {
  .modal-wrapper {
    width: 1000px;
    height: 800px;
  }
}

.legal-modal {
  .modal-wrapper {
    width: 90%;
    height: 90%;
  }
}

.results-modal {
  .modal-wrapper {
    width: 750px;
    height: 660px;
  }
}

.user-history-modal {
  .modal-wrapper {
    width: 750px;
    height: 400px;
  }
}

.community-modal {
  .modal-wrapper {
    width: 750px;
    height: 600px;
  }
}
@media screen and (max-width: 768px),(max-height: 768px) {
  .modal-wrapper{
    width: 100%!important;
    height: 100%!important;
  }
}

.user-history-difficulty-modal {
  .modal-wrapper {
    width: 750px;
    height: 480px;
  }
}
@media screen and (max-width: 768px),(max-height: 768px) {
  .modal-wrapper{
    width: 100%!important;
    height: 100%!important;
  }
}

.skip-training-modal {
  .modal-wrapper {
    width: 750px;
    height: 510px;
  }
}
@media screen and (max-width: 768px),(max-height: 768px) {
  .modal-wrapper{
    width: 100%!important;
    height: 100%!important;
  }
}

@media only screen 
and (min-height: $screen-normal) 
and (min-width: $screen-normal){
  .leader-board-modal,
  .modal-fullscreen{
    .modal-wrapper{
      width: 100%;
      height: 100%;
    }  
  } 
}

@media only screen 
and (min-height: $screen-medium) 
and (min-width: $screen-normal){
  .leader-board-modal,
  .modal-fullscreen{
    .modal-wrapper-md{
      width: 100%;
      height: 100%;
    }  
  }
}
