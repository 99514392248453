.category-heading {
  font-weight: 700;
}

.app-name {
  font-weight: Bold;
  font-size: $base-text;
}
.scale-up-right {
  -webkit-animation: scale-up-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: scale-up-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-4-27 19:26:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
@keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
