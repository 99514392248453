@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.be-menu-item {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
  transition: all 1s ease;
  border-radius: 10px;
  color: #fff;
  background: #fff;
  overflow: hidden;
  .title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    margin: 10px 0 0;
  }

  img {
    margin: 0px 10px;
    width: 20%;
  }

  &.disabled {
    opacity: 0.4;
  }
  .app-details {
    display: flex;
    align-items: center;
    padding: 15px;
  }
}

.menu-header {
  font-size: $base-text;
  font-weight: $font-light;
  padding: 50px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-link {
  font-weight: $font-light;
  font-size: $text-x-small;
  display: block;
  padding: 20px 20px 30px;

  &.last {
    margin-top: 20px;
    border-top: 1px solid $light-gray;
  }
}

.data-boost {
  background-color: white;
  padding: 10px;
}

.featured-activity {
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  height: 200px;
  width: 100%;
  margin: 10px 0px;
}

.activity__info {
  padding: 10px;
  margin: 0;
}

.featured-activity .image-container {
  overflow: hidden;
  width: 100%;
  height: 100px;
}

.featured-activity .image-container img {
  object-fit: cover;
}

.activity__title {
  margin: 5px 0px;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}

.activity__description {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5em;
  color: #555555;
}

.todays-exercises {
  background-color: #179976;
}

.today-heading {
  margin: 0 0 10px 0;
}

.play-now {
  margin: 0;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #10654e;
}

.play-now__btn {
  margin: 0;
  padding: 0;
}

.todays-date {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.category-heading {
  font-weight: 700;
  font-size: 25px;
}

.more-exercises {
  background-color: #1aa6b1;
}

.profile-apps {
  background-color: #e1323a;
}

.exercises-container,
.activity-container {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 600px) {
  .background-container {
    width: 80%;
    margin: 0 auto;
  }

  .be-menu-item,
  .featured-activity {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .be-menu-item {
    width: 350px;
    margin: 10px 10px 10px 0px;
  }

  .featured-activity {
    width: 200px;
    margin: 10px 10px 10px 0px;
  }
}

@media (max-width: 600px) {
  .full-size {
    width: 100%;
  }
}
