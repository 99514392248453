.total-score{
  color: $aqua-green;
  font-family: $secondary-font-stack;
  font-weight: $font-bold;
  font-size: $text-small;
  text-align: right;
  margin-right: 15px;
}

.team-name{
  font-family: $secondary-font-stack;
  font-size: $text-small;
}