$font-path: "../../assets/fonts/";

/* boogaloo-regular - latin */
@font-face {
  font-family: "Boogaloo";
  font-style: normal;
  font-weight: 400;
  src: local("Boogaloo Regular"), local("Boogaloo-Regular"),
    url("#{$font-path}boogaloo/boogaloo-v9-latin-regular.woff2") format("woff2"),
    url("#{$font-path}boogaloo/boogaloo-v9-latin-regular.woff") format("woff"),
    url("#{$font-path}boogaloo/boogaloo-v9-latin-regular.ttf")
      format("truetype"),
    url("#{$font-path}boogaloo/boogaloo-v9-latin-regular.svg#Boogaloo")
      format("svg");
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("#{$font-path}roboto/roboto-v18-latin-300.woff2") format("woff2"),
    url("#{$font-path}roboto/roboto-v18-latin-300.woff") format("woff"),
    url("#{$font-path}roboto/roboto-v18-latin-300.ttf") format("truetype"),
    url("#{$font-path}roboto/roboto-v18-latin-300.svg#Roboto") format("svg");
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("#{$font-path}roboto/roboto-v18-latin-regular.woff2") format("woff2"),
    url("#{$font-path}roboto/roboto-v18-latin-regular.woff") format("woff"),
    url("#{$font-path}roboto/roboto-v18-latin-regular.ttf") format("truetype"),
    url("#{$font-path}roboto/roboto-v18-latin-regular.svg#Roboto") format("svg");
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("#{$font-path}roboto/roboto-v18-latin-700.woff2") format("woff2"),
    url("#{$font-path}roboto/roboto-v18-latin-700.woff") format("woff"),
    url("#{$font-path}roboto/roboto-v18-latin-700.ttf") format("truetype"),
    url("#{$font-path}roboto/roboto-v18-latin-700.svg#Roboto") format("svg");
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("#{$font-path}roboto/roboto-v18-latin-500.woff2") format("woff2"),
    url("#{$font-path}roboto/roboto-v18-latin-500.woff") format("woff"),
    url("#{$font-path}roboto/roboto-v18-latin-500.ttf") format("truetype"),
    url("#{$font-path}roboto/roboto-v18-latin-500.svg#Roboto") format("svg");
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("#{$font-path}open-sans/open-sans-v15-latin-300.woff2") format("woff2"),
    url("#{$font-path}open-sans/open-sans-v15-latin-300.woff") format("woff"),
    url("#{$font-path}open-sans/open-sans-v15-latin-300.ttf") format("truetype"),
    url("#{$font-path}open-sans/open-sans-v15-latin-300.svg#OpenSans")
      format("svg");
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("#{$font-path}open-sans/open-sans-v15-latin-regular.woff2")
      format("woff2"),
    url("#{$font-path}open-sans/open-sans-v15-latin-regular.woff")
      format("woff"),
    url("#{$font-path}open-sans/open-sans-v15-latin-regular.ttf")
      format("truetype"),
    url("#{$font-path}open-sans/open-sans-v15-latin-regular.svg#OpenSans")
      format("svg");
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("#{$font-path}open-sans/open-sans-v15-latin-600.woff2") format("woff2"),
    url("#{$font-path}open-sans/open-sans-v15-latin-600.woff") format("woff"),
    url("#{$font-path}open-sans/open-sans-v15-latin-600.ttf") format("truetype"),
    url("#{$font-path}open-sans/open-sans-v15-latin-600.svg#OpenSans")
      format("svg");
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("#{$font-path}open-sans/open-sans-v15-latin-700.woff2") format("woff2"),
    url("#{$font-path}open-sans/open-sans-v15-latin-700.woff") format("woff"),
    url("#{$font-path}open-sans/open-sans-v15-latin-700.ttf") format("truetype"),
    url("#{$font-path}open-sans/open-sans-v15-latin-700.svg#OpenSans")
      format("svg");
}
