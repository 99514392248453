.be-alert{
  .sc-ion-alert-md{
    max-width: inherit;
    --max-width: inherit;

    &.alert-title,
    &.alert-message,
    &.alert-button{
      font-size: $text-x-small;
    }
  }
}
.alert-wrapper {
  zoom: 1;
}