.question{
  font-size: 1.8rem;
  font-family: $secondary-font-stack;
}

.question-status{
  font-size: $base-text;
  text-align: center;
  padding-bottom: 10px;
}

.artist{
  background: $faded-gray;
  padding: 10px;
}

.answer-block{
  font-family: $secondary-font-stack;
  font-size: $text-small;
  margin-bottom: 15px;
  border-radius: 8px;

  &.correct{
    background-color: $pale-green;
  }

  &.incorrect{
    background-color: $light-red;
  }

  .question{
    font-weight: $font-light;
    font-size: $text-small;
  }

  .answer{
    font-weight: $font-bold;
  }

  .icon{
    font-size: $text-large;

    &.checkmark{
      color: $green;
    }

    &.xmark{
      color: $red;
    }
  }

  .artist-name{
    font-family: $primary-font-stack;
    color: $gray;
    font-style: italic;
    font-size: $text-x-small;
  }
}

.round-number{
  height: 100px;
  width: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $text-medium;
  background: $light-red;
  border: 8px solid $pink;
  font-family: $tertiary-font-stack;
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}
