.be-select{
  font-size: $text-small;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  align-items: center;
  padding-left: 0;
}

.app-select{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.admin-select {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.select-label {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 4px;
  margin-top: 10px;
}