%label-style{
  font-size: $text-x-small;
  font-weight: $font-light;
  padding-left: 16px;
  padding-right: 16px;
  display: block;

  &.admin{
    font-size: $text-x-small;
  }

  &.input-label {
    font-size: $text-medium;
    margin-bottom: 20px;
    font-weight: normal;
  }

  &.font-light {
    font-weight: $font-light;
  }
}

%inline-style{
  font-size: $base-text;
}

.item-native{
  &.sc-ion-item-md,
  &.sc-ion-item-md-s{
    @extend %label-style;
  }
}

.inline-label{
  @extend %inline-style;
  display: inline-block;
}

.item-label{
  &.item-input{
    color: $black;
  }
}

.be-item{
  .sc-ion-label-md-h,
  .sc-ion-label-md-s{
    @extend %label-style;
    white-space: normal;
    padding: 0;

    h2{
      font-size: $text-x-small;
    }

    p{
      font-size: $base-text;
    }
  }
}

.sc-ion-label-md-h,
.sc-ion-label-md-s{
  @extend %label-style;
  white-space: normal;

  h2{
    font-size: $text-x-small;
  }

  p{
    font-size: $base-text;
    line-height: inherit;
  }
}


.label-floating{
  &.sc-ion-label-md-h,
  &.sc-ion-label-md-s{
    font-weight: $font-light;
  }
}

.be-label {
  &.label-stacked {
    &.sc-ion-label-md-h {
      @extend %label-style;
    }
  }
}

.item {
  .be-label {
    &.sc-ion-label-md-h,
    .sc-ion-label-md-h {
      font-size: $text-x-small;
    }
  }  
}
.bar-label {
  font-weight: $font-light;
  font-size: $text-normal;
  line-height: 35px;
}



