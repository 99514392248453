.be-flat-tab {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  transition: all 1s ease;

  button{
    font-size: 1.4rem;
    width: 100%;
    border-radius: 0;
    margin: 0;
    text-transform: uppercase;
    padding: 0px;
    background: #fff;
    border-bottom: 3px solid $light-gray;
    color: $gray;
    -webkit-highlight: none;
    font-weight: $font-medium;
    transition: all 1.5s ease;

    &:focus {
      outline: none;
    }
    &.active{
      border-bottom-color: $primary-color;
      color: #000;
    }
  }
}
