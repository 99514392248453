.be-title{
  &.title-md{
    font-size: $text-small;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 16px;
    text-transform: uppercase;

    &.sc-ion-title-h{
      font-size: $text-small;
    }
  }
}
.title-label {
  font-size: $text-small;
  font-weight: $font-light;
  padding-left: 16px;
  padding-right: 16px;
}
